<script>
export default {
  name: 'SleekButton',
  props: {
    primary: {
      required: false,
      default: true
    },
    outline: {
      required: false,
      default: false
    },
    invert: {
      required: false
    }
  }
}
</script>

<template>
  <button
    class="sleek-button"
    :class="{
      'sleek-button-white': !primary,
      'sleek-button-primary': primary,
      'sleek-button-outline': outline,
      'sleek-button-invert': invert
    }"
    @click="() => $emit('click')"
  >
    <slot />
  </button>
</template>

<style scoped lang="scss">
.sleek-button {
  min-height: 40px;
  width: 190px;
  border-radius: 25px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
}

.sleek-button:hover {
  cursor: pointer;
}

.sleek-button-primary {
  background-color: $level-yellow;
  color: $pitch-black;

  &.sleek-button-outline {
    background: none;
    border: 2px solid $level-yellow;
    color: $level-yellow;
  }
}

.sleek-button-white {
  background-color: $pitch-black;
  color: $level-yellow;

  &.sleek-button-outline {
    background: none;
    border: 2px solid $pitch-black;
    color: $pitch-black;
  }
}

.sleek-button-invert {
  background-color: $level-yellow;
  color: $pitch-black;
  border: 2px solid $pitch-black;
}
</style>
